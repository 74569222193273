<template>
  <div>
    <b-row class="my-3">
      <b-col cols="9">
        <div>
          <b-input
            placeholder="Pesquisar paciente"
            v-model="filters.patient"
            debounce="1000"
          />
        </div>
      </b-col>
      <b-col cols="3">
        <div class="filter-row">
          <b-button variant="primary" @click="getMedicalReportsFiles">
            Aplicar
          </b-button>
          <b-button variant="outline-secondary" v-b-toggle.filters-sidebar>
            <FilterFunnel class="icon" />
            Filtros
            <NotificationDot
              :show="!!activatedFilters"
              tooltip-message="Os filtros estão ativos" />
          </b-button>
        </div>
      </b-col>
    </b-row>

    <section>
      <div id="table-container" v-if="medicalReports.length">
        <b-table-simple id="pending-reports-table" class="reports-table">
          <thead>
          <tr>
            <th>Data do laudo</th>
            <th>Paciente</th>
            <th>Profissional laudador</th>
            <th>Data do exame</th>
            <th>Exame</th>
            <th>Localidade exame</th>
            <th></th>
          </tr>
          </thead>

          <tbody>
          <tr v-for="(report, index) in medicalReports" :key="index">
            <td>
                <span>
                  {{ parseDate(report.updated_at) }}
                </span>
            </td>
            <td>
              <PatientBasicInfo :patient="report.patient" />
            </td>
            <td>
              <div
                class="professional-name"
                v-if="report.attendance_professional"
              >
                <p class="inline-text">
                  {{
                    report.attendance_professional?.name
                  }}
                </p>
              </div>
              <div v-else>
                <p class="inline-text"> - </p>
              </div>
            </td>
            <td class="medical-report-date">
              <p>
                {{
                  report['attendance_date']
                    ? new Date(report['created_at']).toLocaleDateString()
                    : '-'
                }}
              </p>
            </td>
            <td>
              <p class="inline-text">{{ report['item']['name'] || '' }}</p>
            </td>
            <td>
              {{ getLocalidade(report.file.type_id) }}
            </td>
            <td class="filter-row">
              <span :id="`edit-report-${index}`">
              <Edit @click.stop="startAttendance(report, true)" class="icon edit" />
              </span>
              <b-tooltip :target="`edit-report-${index}`" placement="bottom">
                Editar laudo
              </b-tooltip>
              <span :id="`view-report-${index}`">
                <Eye @click="startAttendance(report)" class="icon eye" />
                </span>
              <b-tooltip :target="`view-report-${index}`" placement="bottom">
                Visualizar laudo
              </b-tooltip>
              <span :id="`print-report-${index}`">
                <Printer v-if="report.printed === 0" @click="handlePrint(report)" class="icon printer" />
                <PrinterCheck v-else :style="{ 'stroke': '#305bf2'}" @click="handlePrint(report)" class="icon printer" />
              </span>
              <b-tooltip :target="`print-report-${index}`" placement="bottom">
                {{ report.printed === 1 ? 'Laudo já impresso' : 'Imprimir laudo' }}
              </b-tooltip>
            </td>
          </tr>
          </tbody>
        </b-table-simple>
      </div>
      <div class="pagination-position" v-if="medicalReports.length">
        <b-pagination
          v-model="page"
          :total-rows="count"
          :per-page="limit"
          first-number
          last-number
          size="sm"
        />
      </div>
      <div v-else class="no-data">
        <p>Nenhum exame disponível</p>
      </div>
    </section>
    <AppointmentDocsModal
      :docs="docs"
      :typeDoc="typeDoc"
      @openDocumentFile="openDocumentFile"
    />

    <DocumentModal :documents="documents" @close-modal="clearDocuments" />
    <FiltersSidebar @applyFilters="applyFilters" :showPendingStatus="false" />
  </div>
</template>

<script>
import { getDocTypes } from '@/utils/documentHelper.js'
import {
  userHasPermission,
  getCurrentClinic,
  getCurrentUser
} from '@/utils/localStorageManager'
import { parseDate } from '@/utils/dateHelper'
import { mergePrintableAllPDFs } from '@/utils/PDFHelper'
import { mapActions } from 'vuex';

export default {
  components: {
    AppointmentDocsModal: () =>
      import('@/components/General/AppointmentDocsModal.vue'),
    DocumentModal: () => import('@/components/General/DocumentModal'),
    FilterFunnel: () => import('@/assets/icons/filter-funnel.svg'),
    PatientBasicInfo: () => import('@/components/General/PatientBasicInfo'),
    FiltersSidebar: () => import('./FiltersSidebar.vue'),
    Eye: () => import('@/assets/icons/eye.svg'),
    Printer: () => import('@/assets/icons/icon_printer.svg'),
    PrinterCheck: () => import('@/assets/icons/print_check_02.svg'),
    Edit: () => import('@/assets/icons/edit2.svg'),
    NotificationDot: () => import('@/components/General/NotificationDot.vue')
  },
  props: {
    status: String
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      user: getCurrentUser(),
      isUserAResponsible: false,
      selectedMedicalReport: null,
      medicalReports: [],
      page: 1,
      limit: 0,
      count: 0,
      docs: [],
      filters: {},
      typeDoc: getDocTypes(),
      documents: [],
      showTable: true
    }
  },
  computed: {
    activatedFilters() {
      return this.filters.period?.start
        || this.filters.period?.end
        || this.filters.status
        || this.filters.patient
        || this.filters.professionals?.length
        || this.filters.items?.length
        || this.filters.date_limit
    }
  },
  async mounted() {
    await this.getMedicalReportsFiles()
    await this.getMedicalReportsResponsibles()
  },

  methods: {
    ...mapActions('attendance', ['editAttendance', 'finishAttendance']),
    userHasPermission,
    parseDate,
    applyFilters(filters) {
      this.filters = filters
      this.getMedicalReportsFiles()
    },
    async handlePrint(attendance) {
      const loading = this.$loading.show()
      if(!attendance.file_id) {
        await this.createReportDocumentAndPrint(attendance)
      } else {
        await this.getAttendanceFiles(attendance.attendance_id)
        await this.openDocumentFile(this.docs[0])
      }
      loading.hide()

      this.getMedicalReportsFiles();
    },
    async openPrintableDocument(attendance) {
      const loading = this.$loading.show()
      try {
        await this.getAttendanceFiles(attendance.attendance_id)
        const url = await mergePrintableAllPDFs(this.docs[0])
        window.open('about:blank').document.write(`
          <html>
            <body style="margin:0;">
              <iframe width="100%" height="100%" src="${url}" style="border:none" >
            </body>
          </html>
        `)
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        loading.hide()
      }
    },
    async getMedicalReportsResponsibles() {
      const response = await this.api.allMedicalReportsResponsibles(
        this.clinic.id
      )
      this.isUserAResponsible = response.data.find(
        responsible => responsible.person.id === this.user.id
      )
    },

    async startAttendance(medicalReport, editing) {
      this.editAttendance();
      if (medicalReport.attendance_id) {
        let route = `/laudo-medico/${medicalReport.patient.id}/${medicalReport.attendance_id}/${medicalReport.id}`
        if (editing) {
          route = route + `?editing=${editing}`
          this.$router.push(route)
        } else {
          window.open(this.$router.resolve(route).href, '_blank')
        }
      } else {
        try {
          const formId = await this.getDefaultForm()
          const attendance = await this.api.startAttendance(
            this.clinic.id,
            medicalReport.patient.id,
            null,
            formId,
            'MEDICAL_REPORT'
          )
          await this.api.createOrUpdateMedicalReportFile(
            medicalReport.file_id,
            medicalReport.item_id,
            this.status,
            null,
            attendance.data.id
          )
          this.$router.push(
            `/laudo-medico/${medicalReport.patient.id}/${attendance.data.id}/${medicalReport.id}`
          )
        } catch (error) {
          this.$toast.error(error.message)
        }
      }
    },

    async getDefaultForm() {
      try {
        const response = await this.api.getMedicalReportForm()
        return response.data.id
      } catch (error) {
        this.$toast.error(this.$toast.error(error.message))
      }
    },

    async getMedicalReportsFiles() {
      let professionalFilter = this.filters?.professionals?.map(professional => {
        return professional.value
      }) || []
      if (!userHasPermission('LaudoMedExam2')) {
        professionalFilter = [this.user.id]
      }
      const response = await this.api.getMedicalReportsFiles(
        {
          patient: this.filters.patient,
          period: this.filters.period,
          professionals: professionalFilter,
          items:
            this.filters?.items?.map(exam => {
              return exam.value
            }) || [],
          date_limit: this.filters.date_limit,
          status: ['FINISHED'],
          clinic_id: this.clinic.id
        },
        this.page
      )
      this.medicalReports = []
      response.data.data.map(res => {
        if (res.file !== null || res.labpacs_item_reports) {
          this.medicalReports.push({
            id: res.id,
            file_id: res.file_id,
            file: res.file,
            fileGroup: res.file_group,
            attendance_id: res.attendance_id,
            attendance_date: res.attendance ? res.attendance.updated_at : null,
            item_id: res.item_id,
            patient: res?.file?.patient || res.labpacs_item_reports.patient,
            attendance_professional: res.attendance?.professional,
            professional: res.responsibles,
            exam: res.procedure,
            item: res.item,
            updated_at: res.updated_at,
            created_at: res.created_at,
            printed: res.printed,
            isUserAListedResponsible: res.responsibles.find(
              responsible => responsible.person.id === this.user.id
            )
          })
        }
      })
      this.resume = response.data.resume
      this.limit = response.data.per_page
      this.count = response.data.total
      this.showTable = true
    },

    handlePeriod(period) {
      this.filters.period.start = period.start
      this.filters.period.end = period.end
      this.getMedicalReportsFiles()
    },

    goTo(path) {
      this.$router.push(path)
    },

    openDocumentFile(document) {
      this.documents = document
      this.$bvModal.show('document-modal')
    },
    clearDocuments() {
      this.documents = []
    },
    async createReportDocumentAndPrint(report) {
      const data = {
        attendanceId: report.attendance_id,
        file_id: report.file_id,
        toPrint: [11],
        toSign: [],
        quickPrint: true,
        certContent: null,
        service: null
      }

      try {
        await this.api.finishAttendance(data)
        this.$toast.success('Documentos enviados para impressão')
      } catch (err) {
        this.$toast.error(err.message)
      }
    },
    async getAttendanceFiles(attendanceId) {
      const isLoading = this.$loading.show()
      try {
        const res = await this.api.getAttendanceDocs(attendanceId)
        this.docs = []
        const laudo = Object.keys(res.data).find(key => {
          return res.data[key].some(doc => doc?.type_id === '11')
        })
        this.docs.push(res.data[laudo])
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        isLoading.hide()
      }
    },

    openWhatsApp(number) {
      const whatsappNumber = number.replace(/\D/g, '')
      if (whatsappNumber.length < 10) {
        this.$toast.warning('Número de telefone sem DDD')
        return
      }
      window.open(
        `https://api.whatsapp.com/send?phone=55${whatsappNumber}`,
        '_blank'
      )
    },
    getLocalidade(typeId){
      const types = {
        'intern': 'Realizado internamente',
        'extern': 'Realizado em outra instituição'
      };

      return typeof types[typeId] !== 'undefined' ? types[typeId] : '-';
    }
  },

  watch: {
    status: function() {
      this.showTable = false
      this.filters.status = ['FINISHED']
      this.filters.period = {
        start: null,
        end: null
      }
      this.getMedicalReportsFiles()
    },

    page: function() {
      this.getMedicalReportsFiles()
    }
  }
}
</script>

<style lang="scss" scoped>
#finished-reports-table {
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  table-layout: fixed;
  margin: 5px 0;

  thead {
    font-weight: 600;
    color: var(--dark-blue);
    border-radius: 8px 8px 0 0;

    tr {
      background-color: var(--neutral-100);

      &:first-child {
        border-radius: 8px 8px 0 0;

        th {
          &:first-child {
            border-radius: 8px 0 0 0;
          }

          &:last-child {
            border-radius: 0 8px 0 0;
          }
        }
      }
    }
  }
}

.icon {
  cursor: pointer;
  fill: var(--type-active);
  width: 24px;
  height: 24px;

  &.printer {
    fill: none;
    stroke: var(--type-active);
  }
}

.no-data {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;

  p {
    text-align: center;
  }
}

.filter-row {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: end;
}

.badge {
  padding: 8px;
  color: var(--type-active);
}
</style>
